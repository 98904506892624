import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import MainInitLang from "@/utils/Lang/MainInitLang";
import { onMounted } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    onMounted(() => {
      MainInitLang();
    });
    return (_ctx, _cache) => {
      const _component_RouterView = _resolveComponent("RouterView");
      return _openBlock(), _createBlock(_component_RouterView);
    };
  }
};