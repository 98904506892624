import { createApp } from 'vue'
import App from './App.vue'
//  引入路由
import VueRouterConfig from "@/router/VueRouterConfig";
//  引入Vant样式
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';
//引入公用css
import '@/assets/common.css';

const vm = createApp(App);
vm.use(VueRouterConfig);
vm.mount('#app');
