/**
 * 共用页面
 * 例如:登录,报错页面等,结果页面等...
 */
const pages = [
    {
        // 访问错误页面
        path:'/:pathMatch(.*)*',
        name:'Common.ErrorPage_NotFound',
        component:()=>import('@/views/Common/ErrorPage_NotFound'),
    },
    {
        // 登录页面
        path:'/:version',
        name:'Common.Login',
        component:()=>import('@/views/Common/Login'),
    },
    {
        // 显示执行结果页面
        path:'/Result',
        name:'Common.Result',
        component:()=>import('@/views/Common/Result'),
    },
];
export default pages;