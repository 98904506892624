const Pages_Agent = [
    //代理列表
    {
        path:'Agent/AgentList',
        name:'Agent.AgentList',
        component:()=>import('@/views/Agent/AgentList'),
    },
    //添加下级
    {
        path:'Agent/AddAgent',
        name:'Agent.AddAgent',
        component:()=>import('@/views/Agent/AddAgent'),
    },
    //下级代理商用户管理
    {
        path:'Agent/AgentUserList/:UserName',
        name:'Agent.AgentUserList',
        component:()=>import('@/views/Agent/AgentUserList'),
        props:true,
    },
    //下级代理商用户管理.回迁用户
    {
        path:'Agent/AgentUserList-FetchUser',
        name:'Agent.AgentUserList-FetchUser',
        component:()=>import('@/views/Agent/AgentUserList-FetchUser'),
        props:true,
    },
    //代理日志
    {
        path:'Agent/AgentLog/:UserName',
        name:'Agent.AgentLog',
        component:()=>import('@/views/Agent/AgentLog'),
        props:true,
    },
];
export default Pages_Agent;