const Pages_User = [
    //用户列表
    {
        path:'User/UserList',
        name:'User.UserList',
        component:()=>import('@/views/User/UserList'),
    },
    //回收用户
    {
        path:'User/RecoveryUser',
        name:'User.RecoveryUser',
        component:()=>import('@/views/User/RecoveryUser'),
    },
    //迁移用户
    {
        path:'User/MoverUser',
        name:'User.MoverUser',
        component:()=>import('@/views/User/MoverUser'),
    },
    //批量充值
    {
        path:'User/BatchRecharge',
        name:'User.BatchRecharge',
        component:()=>import('@/views/User/BatchRecharge'),
    },
    //注册日志
    {
        path:'User/BatchRegUserLog',
        name:'User.BatchRegUserLog',
        component:()=>import('@/views/User/BatchRegUserLog'),
    },
    //批量注册
    {
        path:'User/BatchRegUser',
        name:'User.BatchRegUser',
        component:()=>import('@/views/User/BatchRegUser'),
    },
    //下载记录
    {
        path:'User/UserDownLoadLog/:UserName',
        name:'User.UserDownLoadLog',
        component:()=>import('@/views/User/UserDownLoadLog'),
        props:true,
    },
    //充值码使用记录
    {
        path:'User/UserUseKeyLog/:UserName',
        name:'User.UserUseKeyLog',
        component:()=>import('@/views/User/UserUseKeyLog'),
        props:true,
    },
];
export default Pages_User;