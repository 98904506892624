/**
 * [main.js]初始化Vant组件语言
 */
import { Locale } from 'vant';
// 引入语言包
import zhCN from 'vant/es/locale/lang/zh-CN';
import enUS from 'vant/es/locale/lang/en-US';
import LangBase from "@/utils/Lang/common/LangBase";

const initVantLang = () => {
    let lib = new LangBase();
    let language = lib.getLanguage();
    switch (language) {
        case 'zh':
            Locale.use('\tzh-CN', zhCN);
            break;
        case 'en':
            Locale.use('en-US', enUS);
            break;
        default :
            Locale.use('en-US', enUS);
    }
};

export default initVantLang;