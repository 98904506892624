import JsCookie from 'js-cookie';

const Lib = {
    /**
     * 设置cookie
     * @param field
     * @param value
     * @param expires [有效时间,单位为天]不设置则关闭浏览器后失效
     * @return {string}
     * @constructor
     */
    SetCookie(field,value,expires = null){
        let options = {};
        if (expires){
            options.expires = Number(expires);
        }
        return JsCookie.set(field,value,options);
    },

    /**
     * 获取cookie
     * @param field
     * @return {*}
     */
    getCookie(field){
        return JsCookie.get(field);
    }
};

export default Lib;