/**
 * 公用函数,字符串相关
 */
const Lib = {
    /**
     * 检查父字符串是否存在子字符串[不区分大小写]
     * @param str 父字符串
     * @param findStr 查找的字符串[子字符串]
     * @returns {boolean}
     */
    checkExistString(str,findStr){
        let call = (str,findStr)=>{
            let res =
                str.indexOf(findStr) !== -1
                ||
                str.toLowerCase().indexOf(findStr.toLowerCase()) !== -1
            ;
            return res;
        }
        let bool = call(str,findStr);
        if (!bool){
            bool = call(str,this.ReplaceIllegalChar(findStr));
        }
        return bool;
    },

    /**
     * [替换非法字符]
     * 基于规则过滤字符串,不符合规则将会被替换
     * @param str
     * @returns {*}
     * 返回[汉字,英文,数字,@,_,-]六种字符,其他统一替换成[_]
     */
    ReplaceIllegalChar(str) {
        return str.replace(/[^\w\u4e00-\u9fa5@-]/g, '_');
    },

    /**
     * 前补零（str不能为空，否则会少一位TT）
     * @param str 字符串
     * @param n 总长度(n-字符串长度 = 要补0的数量]
     * @returns {string}
     */
    fixZeroStart(str, n) {
        if (str.length >= n){
            //如果总长度小于字符串的长度,则直接返回原字符串
            return str;
        }
        return (Array(n).join(0) + str).slice(-n);
    },
}
export default Lib;