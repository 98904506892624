/**
 * [多语言]基类
 */
import CookieLib from "@/utils/Cache/common/CookieLib";
import Common_Str from "@/utils/Common/Common_Str";

class LangBase {
    //语言包[对象]
    #lang;
    //当前语种
    nowLang;

    constructor() {
        this.LangField = 'NowLanguage';
        this.nowLang = this.getLanguage();
    }

    /**
     * 获取当前语言名字
     * 如果未设置则会智能识别[判断是中文或者英文]并设置Cookie
     * ↑如果不成功则返回默认中文
     */
    getLanguage(){
        let lang = CookieLib.getCookie(this.LangField);
        if (lang){
            return lang;
        }
        lang = 'zh';
        let langs = navigator.languages;
        if (langs){
            let isCn = false;
            for (let langsKey in langs) {
                let name = langs[langsKey];
                let bool =
                    Common_Str.checkExistString(name,'zh')
                    ||
                    Common_Str.checkExistString(name,'cn');
                if (bool){
                    isCn = true;
                    break;
                }
            }
            lang = isCn ? 'zh' : 'en';
        }
        this.SetNowLang(lang);
        return lang;
    }

    /**
     * 设置当前语言包
     * @param LangName
     */
    SetNowLang(LangName){
        this.nowLang = LangName;
        CookieLib.SetCookie(this.LangField,LangName,60);
    }
}

export default LangBase;