//引入页面
import Pages_Common from "@/router/Pages/Pages_Common";
import Pages_Ohter from './Pages/Pages_Ohter';
import Pages_User from "@/router/Pages/Pages_User";
import Pages_Key from "@/router/Pages/Pages_Key";
import Pages_Agent from "@/router/Pages/Pages_Agent";
const routes = [
    //公用页面[登录,错误页面,提示页面...]
    ...Pages_Common,
    //后台
    {
        path:'/Home',
        name:'Home',
        component:()=>import('@/layout/Layout'),
        children:[
            //其他页面[个人信息,修改密码,等..]
            ...Pages_Ohter,
            //用户模块
            ...Pages_User,
            //卡密模块
            ...Pages_Key,
            //代理商模块
            ...Pages_Agent,
        ],
    }
];

export default routes;