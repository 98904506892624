const pages_Ohter = [
    //当前登录代理商数据报表
    {
        path:'Other/SelfInfo',
        name:'Other.SelfInfo',
        component:()=>import('@/views/Other/SelfInfo'),
    },
    //导航
    {
        path: 'Other/OtherIndex',
        name: 'Other.OtherIndex',
        component:()=>import('@/views/Other/OtherIndex'),
    },
    //修改自己密码
    {
        path: 'Other/SetSelfPass',
        name: 'Other.SetSelfPass',
        component:()=>import('@/views/Other/SetSelfPass'),
    },
    //退出登录
    {
        path: 'Other/LoginOut',
        name: 'Other.LoginOut',
        component:()=>import('@/views/Other/LoginOut'),
    },
];
export default pages_Ohter;