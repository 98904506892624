const Pages_Key = [
    //卡密列表
    {
        path:'Key/KeyList/:UserName',
        name:'Key.KeyList',
        component:()=>import('@/views/Key/KeyList'),
        props: true,
    },
    //批量生成
    {
        path:'Key/MakeKey',
        name:'Key.MakeKey',
        component:()=>import('@/views/Key/MakeKey'),
    },
    //迁移卡密
    {
        path:'Key/MoveKey/:Mode',
        name:'Key.MoveKey',
        component:()=>import('@/views/Key/BatchKey'),
        props:true,
    },
    //批量生成卡密记录
    {
        path:'Key/MakeKeyLog',
        name:'Key.MakeKeyLog',
        component:()=>import('@/views/Key/MakeKeyLog'),
    },
];
export default Pages_Key;